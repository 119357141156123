/* istanbul ignore file */

// https://flowbite.com/docs/components/stepper/

import { graphql } from "gatsby";
import React from "react";
import { TipsListDataFragment } from "../../../graphql-types";
import { RichTextRenderer } from "../RichTextRenderer";

type Props = TipsListDataFragment;

export default function TipsList({ tips }: Props) {
  return (
    <ol className="mx-auto px-4 max-w-xs flex flex-col gap-8">
      {tips.map((tip, index) => (
        <li key={index + tip.title} className="flex items-center gap-4">
          <span className="flex text-secondary-500 items-center justify-center size-8 border border-secondary-500 rounded-full shrink-0 mb-auto drop-shadow-[-1px_1px_currentColor] bg-white font-bold">
            {index + 1}
          </span>
          <div className="">
            <span className="font-bold block mb-2 text-primary-500">
              {tip.title}
            </span>
            <RichTextRenderer
              className="text-md"
              component="div"
              raw={tip.body.raw}
            />
          </div>
        </li>
      ))}
    </ol>
  );
}

export const fragment = graphql`
  fragment TipsListData on TipsList {
    tips {
      id
      title
      body {
        raw
      }
    }
  }
`;
